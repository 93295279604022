import { addToCartByCartLine, addToCartSources } from '/Foundation/CDP/code/Utilities/cdpHelper';

document.addEventListener('DOMContentLoaded', function () {
  const ADD_CART_LINE = '/api/sitecore/cart/addcartline';

  const modalElement = document.getElementById('addToCartModal');
  const closeButton = modalElement.querySelector('.hide-add-to-cart-modal');
  const backdropElement = document.getElementById('addToCartModalBackdrop');
  const accessoryAddToCartButtons = modalElement.querySelectorAll('.accessory-atc-button');

  closeButton.addEventListener('click', () => {
    modalElement.classList.remove('wrapper-visible');
    backdropElement.classList.remove('active');
    document.documentElement.classList.remove('overflow-hidden');
    document.body.classList.remove('overflow-hidden');
  });

  backdropElement.addEventListener('click', () => {
    modalElement.classList.remove('wrapper-visible');
    modalElement.setAttribute('aria-hidden', true);
    backdropElement.classList.remove('active');
    document.documentElement.classList.remove('overflow-hidden');
    document.body.classList.remove('overflow-hidden');
  });

  accessoryAddToCartButtons.forEach((button) => {
    button.addEventListener('click', () => {
      let productId = button.dataset.productId;

      fetch(ADD_CART_LINE, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productsData: [
            {
              productId: productId,
              category: getProductCategory(button),
            },
          ],
          quantity: 1,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          button.classList.add('success');

          let textElement = button.querySelector('span');
          let iconElement = button.querySelector('i');
          textElement.innerHTML = textElement.dataset.successText;
          iconElement.classList.remove(iconElement.dataset.icon);
          iconElement.classList.add(iconElement.dataset.successIcon);

          document.querySelector('.product-detail-modal-cart-counter').innerHTML = response.TotalQuantity;

          trackToCDP(response?.Cart, productId);

          setTimeout(() => {
            button.classList.remove('success');
            textElement.innerHTML = textElement.dataset.text;
            iconElement.classList.add(iconElement.dataset.icon);
            iconElement.classList.remove(iconElement.dataset.successIcon);
          }, 3000);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    });
  });
});

const getProductCategory = (button) => {
  let url = button.dataset.url;

  if (!url) url = window.location.href;
  try {
    let category = google.tagmanager.parseCategoryFromUrl(url);
    return category;
  } catch (error) {}
};

const trackToCDP = (cart, productId) => {
  if (typeof addToCartByCartLine === 'undefined' || !cart?.Lines || !productId) {
    return;
  }

  addToCartByCartLine(cart, productId, addToCartSources.upsell);
};
